<template>
  <div class="my">
    <div class="head">
      <div class="title">
        <p>{{ $t('loginBox.introduce1') }}</p>
        <p>{{ $t('loginBox.introduce2') }}</p>
      </div>
      <div class="form">
        <div class="input">
          <!-- <el-dropdown size="small" trigger="click" placement="bottom-start">
            <span class="el-dropdown-link" style="cursor: pointer;">
              {{ value }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="value in areaCode" :key="value.value">{{ value.value }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
          <el-select class="prefix" v-model="value">
            <div class="prefixSearch">
              <el-input v-model="prefixSearch" prefix-icon="el-icon-search" @input="inputSearch" :placeholder="$t('loginBox.search')"></el-input>
            </div>
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              <!-- <span>{{ item.label }}</span>
              <span>{{ item.value }}</span> -->
            </el-option>
          </el-select>
          <div class="wire"></div>
          <el-input
            class="right_input"
            v-model.trim="formData.phone"
            :placeholder="$t('loginBox.inputPhone')"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange"
          ></el-input>
        </div>
        <div class="input inputCode" style="margin-top: 18px;" slot="footer">
          <el-input
            :style="{ width: phoneCode ? '235px' : '' }"
            v-model.trim="formData.code"
            :placeholder="$t('loginBox.inputCode')"
            type="text"
            oninput="value=value.replace(/[^\d.]/g,'')"
            @blur="salaryChange2"
          ></el-input>
          <el-button v-show="!phoneCode" class="btn" @click="GetCodeBtn"
            >{{ $t('loginBox.List') }}</el-button>
          <span v-show="phoneCode" class="phoneCode" @click="NoCodeBtn">{{
            phoneCode + $t('loginBox.codeList')
          }}</span>
        </div>
      </div>
      <div class="login">
        <div class="agreement">
          <el-checkbox v-model="checked"></el-checkbox>
          <p>{{ $t('loginBox.read') }}<span @click="privacyDialog($t('loginBox.userAgreement'))" style="color: #ffd92a;cursor: pointer;">{{ '《' + $t('loginBox.userAgreement') + '》' }}</span>{{ $t('loginBox.and') }}<span @click="privacyDialog($t('loginBox.privacyPolicy'))" style="color: #ffd92a;cursor: pointer;">{{ '《' + $t('loginBox.privacyPolicy') + '》' }}</span></p>
        </div>
        <el-button class="btn" @click="loginBtn">{{ $t('loginBox.login') }}</el-button>
      </div>
    </div>
    <p class="out" @click="outBtn">{{ $t('loginBox.cancel') }}</p>
    <new-dialog :isShowDialog.sync="isShowDialog" :title.sync="title" @showDialog="isShowDialog = false"></new-dialog>
  </div>
</template>

<script>
import { checkPhone } from '@/common/common.js'
import { dataInJs } from '@/components/Nftinquire-box/phoneArea.js'
import newDialog from '../Dialog/dialog.vue'
import { TencentCode, getCode, login } from '@/api/api.js'
import '@/assets/js/TCaptcha.js'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    newDialog
  },
  data() {
    return {
      isShowDialog: false,
      formData: {
        phone: '',
        code: '',
      },
      prefixSearch: '',
      checked: false,
      value: '+852', // 手机前缀
      phoneCode: 0,
      timer: null,
      title: '',
      list: dataInJs(),
      // list: this.language == 'en' ? dataInJs().enArr : (this.language == 'zh-CN' ? dataInJs().zhArr : dataInJs().twArr),
    }
  },
  computed: {
    ...mapState(['language']),
      // list:{
        // list() {
        //   let arr = this.language == 'en' ? dataInJs().enArr : (this.language == 'zh-CN' ? dataInJs().zhArr : dataInJs().twArr)
        // return arr
        // },
      // }
    },
    watch: {
    },
  mounted() {
    console.log()
  },
  methods: {
    ...mapMutations(['setTokenInfo', 'setLoginShow']),
    privacyDialog(title) {
      this.isShowDialog = true
      this.title = title
      console.log(title)
      console.log(this.$t('userAgreement.userAgreement'))
      if(title == this.$t('userAgreement.userAgreement')) {
        console.log(11111111)
      } else {
        console.log(222222222222222220)
      }
      // console.log(title === 'Terms and Conditions')
    },
    GetCodeBtn() {
      var Captcha = new TencentCaptcha('2009916787', async(res) => {
        // 返回结果
        // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
        // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
        // CaptchaAppId       String    验证码应用ID。
        // bizState    Any       自定义透传参数。
        // randstr     String    本次验证的随机串，请求后台接口时需带上。
        if (res.ret == 0) {
          //     TencentCode({ randstr: res.randstr, ticket: res.ticket }).then(res => {
          //   console.log(res, '这里')
          // }).catch(err => {
          //   console.log(err, '错误')
          // })
          const res2 = await TencentCode({
            randstr: res.randstr,
            ticket: res.ticket,
            permission: 'no',
          })
          if (res2.data.unlockLogin) {
            const res3 = await getCode({
              mobile: this.value + this.formData.phone,
              permission: 'no',
            })
            if (res3.data.countDown) {
              this.$message.success(this.$t('message.sendOk'))
              this.phoneCode = res3.data.countDown
              this.timer = setInterval(() => {
                if (res3.data.countDown > 0 && this.phoneCode > 0) {
                  this.phoneCode--
                } else {
                  clearInterval(this.timer)
                  this.phoneCode = 0
                }
              }, 1000)
            }
          }
        } else {
          this.$notify.warning(this.$t('message.verification'))
          // console.log(res)
        }
      })
      if (this.formData.phone && checkPhone(this.formData.phone)) {
        Captcha.show()
        var title = document.getElementById('guideText')
      } else if (!this.formData.phone) {
        this.$message.error(this.$t('message.inputPhone'))
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error(this.$t('message.inputPhoneOk'))
      }
    },
    NoCodeBtn() {
      this.$message.error(this.$t('message.oftenNo'))
    },
    async loginBtn() {
      if (
        this.formData.code &&
        checkPhone(this.formData.phone) &&
        this.checked
      ) {
        const res = await login({
          code: this.formData.code,
          mobile: this.formData.phone,
          mobilePrefix: this.value,
          permission: 'no',
        })
        let userInfo = res.data.user
        userInfo.token = res.data.token
        this.setTokenInfo(userInfo)
        this.$message.success(this.$t('message.loginOk'))
        this.formData.phone = ''
        this.formData.code = ''
        this.checked = false
        this.value = '+852'
        this.setLoginShow(false)
        this.$emit('updateMarketList')
      } else if (!this.formData.phone) {
        this.$message.error(this.$t('message.inputPhone'))
      } else if (!checkPhone(this.formData.phone)) {
        this.$message.error(this.$t('message.inputPhoneOk'))
      } else if (!this.formData.code) {
        this.$message.error(this.$t('message.inputCode'))
      } else if (this.formData.code.length > 6) {
        this.$message.error(this.$t('message.inputCodeOk'))
      } else if (!this.checked) {
        this.$message.error(this.$t('message.readAgreement'))
      }
    },
    outBtn() {
      this.setLoginShow(false)
    },

    salaryChange(e) {
      this.formData.phone = e.target.value
    },
    salaryChange2(e) {
      this.formData.code = e.target.value
    },
    inputSearch() {
      let newArr = []
      let arr = dataInJs()
      for (var i = 0; i < arr.length; i++) {
      if (arr[i].label.indexOf(this.prefixSearch) >= 0) {
        newArr.push(arr[i]);
      }
    }
    this.list = newArr
    },
  },
}
</script>

<style lang="less" scoped>
  .prefixSearch {
    top: 0;
    padding: 10px 5px;
  }
</style>
