<template>
  <div class="mearket_list">
    <!-- <div class="hint"></div> -->
    <div class="head_logo">
      <div
        class="login-box"
        @click="
          !userInfo.token
            ? setLoginShow(true)
            : $router.push('/nftmarket/marketPayList')
        "
      >
        <div class="noLogin" v-if="!userInfo.token">
          <img src="/img/Group 103@2x.png" alt="" />
          <p>{{ $t('marketList.login') }}</p>
        </div>
        <div class="userLogin" v-else>
          <img
            class="userImg"
            style="border-radius: 50%;"
            :src="userInfo.imagePath ? userInfo.imagePath : headImg"
            alt=""
          />
          <p class="phone">
            {{ userInfo.name + '&nbsp;' + verifyPhone(userInfo.mobile) }}
          </p>
          <img class="rightImg" src="/img/arrow-right.png" alt="" />
        </div>
      </div>
      <div class="language-option">
          <el-dropdown
          trigger="click"
          class="international"
          @command="handleSetLanguage"
          placement="bottom"
        >
          <div class="el-dropdown-link my_message"
            >{{ $t('language.language')
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="header-new-drop">
            <el-dropdown-item
              :disabled="language === $t('language.zh')"
              command="zh-CN"
              >{{ $t('language.zhName') }}</el-dropdown-item
            >
            <el-dropdown-item
              :disabled="language === $t('language.tw')"
              command="tw"
              >{{ $t('language.twName') }}</el-dropdown-item
            >
            <el-dropdown-item
              :disabled="language === $t('language.en')"
              command="en"
              >{{ $t('language.enName') }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="below_cotent">
      <div class="below_left">
        <!-- <div class="head">
          <div class="price" @click="priceSort">
            <img src="/img/market_group 9.png" alt="" />&nbsp;Price
          </div>
          <div class="brush" @click="reset">
            <img src="/img/market_shape.png" alt="" />
          </div>
        </div> -->
        <div class="head">
          <img class="sortImg" :src="sort == 0 ? '/img/Group 9 Copy 3.png' : 'img/Group 9 Copy 2.png'" alt="">
          <el-select v-model="sort" @change="sortChange">
      <el-option
      :label="$t('marketList.sortAscending')"
      value="0">
      <div class="imgItem" style="display: flex;align-items: center;">
        <img style="margin-right: 8px; width: 21px; height: 17px; margin-top: 5px;" src="/img/Group 9 Copy 3.png" alt="">
        <span style="display: block; padding-top: 5px;">{{ $t('marketList.sortAscending') }}</span>
      </div>
      </el-option>
      <el-option
      :label="$t('marketList.sortDescending')"
      value="1">
      <div class="imgItem" style="display: flex;align-items: center;">
        <img style="margin-right: 8px; width: 21px; height: 17px; margin-top: 5px;" src="/img/Group 9 Copy 2.png" alt="">
        <span style="display: block; padding-top: 5px;">{{ $t('marketList.sortDescending') }}</span>
      </div>
      </el-option>
    </el-select>
        </div>
        <div class="NFT_Chain" style="margin-top: 0;">
          <div class="title">NFT Chain</div>
          <img
            @click="reset"
            class="eliminate-logo"
            src="/img/Group 14 Copy@2x.png"
            alt=""
          />
          <div
            :class="['Solana', item.isSeries ? 'bgs' : '']"
            v-for="item in ChainList"
            :key="item.id"
            @click="ChainActive(item)"
          >
            <img style="width: 29px; height: 29px;" :src="item.img" alt="" />{{
              item.name
            }}<img
              :class="item.isSeries ? 'activeGou' : 'image2'"
              :src="!item.isSeries ? '' : '/img/marketPath6.png'"
              alt=""
            />
          </div>
        </div>
        <div class="NFT_Series">
          <div class="title">NFT Series</div>
          <img
            @click="resetSeries"
            class="eliminate-logo"
            src="/img/Group 14 Copy@2x.png"
            alt=""
          />
          <div
            class="SeriesBtn"
            v-for="item in SeriesList"
            :key="item.index"
            @click="SeriesBtn(item)"
          >
            <img :src="item.logo" alt="" />
            <img
              v-show="item.isSeries"
              class="activeSeries"
              src="/img/marketgou.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="below_right">
        <!-- 暂无数据 -->
        <div v-if="noData === 1" class="noData">
          <img src="/img/Group44@2x.png" alt="" />
          <div style="margin-bottom: 10px;">{{ $t('marketList.noData') }}</div>
          <!-- <div>No data</div> -->
        </div>
        <!-- 暂无数据 -->
        <div v-else class="right_img" v-for="item in List" :key="item.index">
          <div class="top" @click="detailsBtn(item)">
            <img class="img" :src="item.nftPath" alt="" width="100%" />
            <img
              @click.stop="magnifyBtn(item.nftPath)"
              class="search"
              src="/img/market_zoom.png"
              alt=""
            />
            <div class="Solana">
              <img
                :src="
                  item.chain === 'Solana'
                    ? '/img/SOLANA.png'
                    : item.chain === 'Ethereum'
                    ? '/img/marketgroup9copy.png'
                    : '/img/marketgroup10copy.png'
                "
                alt=""
              />
              <span>{{ item.chain }}</span>
            </div>
          </div>
          <div class="bottom">
            <div class="name_price">
              <span class="name">{{ item.code }}</span>
              <span class="price">{{
                priceDispose(item.price) + '&nbsp;' + item.currency
              }}</span>
              <span class="price">{{
                item.status === 'sold'
                  ? $t('marketList.soldOut')
                  : item.status === 'locked'
                  ? $t('marketList.inTrading')
                  : $t('marketList.vendibility')
              }}</span>
            </div>
            <div class="zan" @click="updateLike(item)">
              <!-- /img/market_zan.png -->
              <img
                :src="
                  LikeList.some((v) => v.nftId === item.nftId)
                    ? '/img/like@2x.png'
                    : '/img/market_zan.png'
                "
                alt=""
              />
              <span>{{ item.likes }}</span>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            :hide-on-single-page="page.total <= 1 ? true : false"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            :page-sizes="[18, 27, 36, 45]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      width="0"
      :visible.sync="loginShow"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <login-box
        @updateMarketList="updateMarketList"
        class="login_box"
      ></login-box>
    </el-dialog>
    <el-dialog width="600px" :visible.sync="dialogVisible">
      <img width="100%" style="height: 600px" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import LoginBox from '../login/login-box.vue'
import { mapState, mapMutations } from 'vuex'
import { verifyPhone } from '@/common/common.js'
import { headImg } from '@/common/common.js'
import {
  marketList,
  getSysLikeList,
  updateLike,
  getCategoryList,
} from '../../api/api'
import { priceDispose } from '@/common/common.js'
export default {
  name: 'nftmarketbox',
  components: {
    LoginBox,
  },
  data() {
    return {
      headImg: localStorage.getItem('headImg'),
      verifyPhone: verifyPhone,
      dialogVisible: false,
      dialogImageUrl: '',
      LikeList: [], // 查看用户点赞数据
      noData: 0,
      page: {
        page: 1,
        pageSize: 18,
        total: 0,
      },
      priceDispose,
      sort: '0',
      List: [
        // {
        //   id: 1,
        //   nftPath: '/img/market_right.png',
        //   name: 'Solana',
        //   code: '111',
        //   status: 'sold',
        //   likes: '99',
        // }
      ], // 右侧内容数据
      ChainList: [
        {
          id: 1,
          img: '/img/SOLANA.png',
          name: 'Solana',
          isSeries: false,
        },
        {
          id: 2,
          img: '/img/marketgroup9copy.png',
          name: 'Ethereum',
          isSeries: false,
        },
        {
          id: 3,
          img: '/img/Group 9 Copy@2xnew.png',
          name: 'Polygon',
          isSeries: false,
        },
        // {
        //   id: 3,
        //   img: '/img/marketgroup10copy.png',
        //   name: 'Polygon',
        //   isSeries: false,
        // },
      ], // Chain数据
      SeriesList: [], // Series数据
    }
  },
  computed: {
    ...mapState(['loginShow', 'userInfo', 'language']),
    newChain() {
      return this.newFormData(this.ChainList)
    },
    newSeries() {
      return this.newFormData(this.SeriesList)
    },
  },
  created() {
    if (!localStorage.getItem('headImg')) {
      this.getHeadImg()
    }
    this.marketList()
    this.getCategoryList()
  },
  methods: {
    ...mapMutations(['setLoginShow']),
    // 讓用戶隨機使用默認頭像
    getHeadImg() {
      if (!localStorage.getItem('headImg')) {
        let a = Math.floor(Math.random() * 10)
        let img = headImg[a].imgUrl
        localStorage.setItem('headImg', img)
        this.headImg = img
      }
    },
    // 子组件通知父组件更新数据
    updateMarketList() {
      this.marketList()
    },
    async getCategoryList() {
      const res = await getCategoryList()
      res.data.forEach((item) => {
        item.isSeries = false
      })
      this.SeriesList = res.data
    },
    async updateLike(obj) {
      let isLike = null
      let LikeList = this.LikeList
      if (LikeList.length === 0) {
        isLike = '1'
      } else {
        var a = LikeList.some((item) => {
          return item.nftId === obj.nftId
        })
      }
      if (a) {
        isLike = '0'
      } else {
        isLike = '1'
      }
      const res = await updateLike({
        mobile: this.userInfo.name + this.userInfo.mobile,
        nftId: obj.nftId,
        isLike,
      })
      this.marketList()
      this.$message.success(res.message)
    },
    // async getSysLikeList() {

    // },
    newFormData(List) {
      let newArr = []
      List.forEach((item) => {
        if (item.isSeries) {
          newArr.push(item.name)
        }
      })
      return newArr
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.marketList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.page.page = val
      this.marketList()
    },
    async marketList() {
      const marketList2 = marketList({
        page: this.page.page,
        pageSize: this.page.pageSize,
        chain: this.newChain,
        category: this.newSeries,
        sort: this.sort,
      })
      const res = await marketList2
      this.List = res.data.result
      if (this.userInfo.token) {
        const getSysLikeList2 = getSysLikeList()
        const res2 = await getSysLikeList2
        this.LikeList = res2.data
      }
      this.page.total = res.data.total
      if (this.List.length === 0) {
        this.noData = 1
      } else {
        this.noData = 0
      }
    },
    ChainActive(item) {
      this.page.page = 1
      item.isSeries = !item.isSeries
      this.marketList()
    },
    SeriesBtn(item) {
      this.page.page = 1
      item.isSeries = !item.isSeries
      this.marketList()
    },
    detailsBtn(obj) {
      this.$router.push(
        `/nftmarket/marketDetails?nftCommodityId=${obj.nftCommodityId}`
      )
      window.scrollTo(99999, 0)
    },
    magnifyBtn(url) {
      this.dialogVisible = true
      this.dialogImageUrl = url
    },
    // 清除NFT Chain筛选
    reset() {
      this.ChainList.forEach((item) => {
        item.isSeries = false
      })
      this.sort = ''
      this.marketList()
    },
    // 清除NFT Series筛选
    resetSeries() {
      this.SeriesList.forEach((value) => {
        value.isSeries = false
      })
      this.sort = ''
      this.marketList()
    },
    // 控制右边商品列表价格排序
    // priceSort() {
    //   if (this.sort === '1') {
    //     this.sort = '0'
    //   } else if (this.sort === '0') {
    //     this.sort = '1'
    //   } else if (this.sort === '') {
    //     this.sort = '1'
    //   }
    //   this.marketList()
    // },
    // 控制右边商品列表价格排序
    sortChange(val) {
      this.sort = val
      this.marketList()
    },
    // 把选择的语言传入vuex
    handleSetLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    },
  },
}
</script>

<style>
.header-new-drop {
  position: absolute !important;
  background-color: rgba(0, 0, 0, 0.78) !important;
  border: none;
  width: 128px;
  top: 222px !important;
  padding: 0;
}
.el-dropdown-menu__item {
  padding: 0;
  line-height: 0;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dropdown-menu__item:hover {
  background-color: rgba(0, 0, 0, 0.78) !important;
  color: #ffd92a !important;
}
.el-dropdown {
  color: #fff;
  font-size: .14rem;
}
.popper__arrow {
  display: none !important;
}
.international {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dropdown-link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-select-dropdown {
          background-color: #1b1b1b !important;
          border-color: #1B1B1B !important;
          border-radius: 17px;
        }
        .el-popper[x-placement^=bottom] {
          margin-top: -0.47rem;
        }
        .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
          background-color: #1b1b1b;
          color: #FFD92A;
        }
        .el-select-dropdown__item.selected {
          color: #FFD92A;
        }
</style>
